import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
// import { useHistory } from 'react-router-dom';
import { useRouter } from 'next/router';
import { connect } from 'react-redux';
import { MdClose } from '@react-icons/all-files/md/MdClose';
import { AiOutlineUser } from '@react-icons/all-files/ai/AiOutlineUser';
import { IoNotificationsOutline } from '@react-icons/all-files/io5/IoNotificationsOutline';
import { IoSettingsOutline } from '@react-icons/all-files/io5/IoSettingsOutline';
import { FiLogOut } from '@react-icons/all-files/fi/FiLogOut';
import * as userActions from '../../ducks/user';
import * as notificationActions from '../../ducks/notification';
import Level from '../level';
import ExpProgressBar from '../expProgressBar';
import Button from '../button';
import Avatar from '../avatar';
import styles from './index.module.css';

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        zIndex: 99,
    },
    content: {
        top: 0,
        width: 250,
        left: 'auto',
        right: 0,
        bottom: 0,
        border: 'none',
        padding: 0,
        borderRadius: 0,
    },
};

const NavDrawer = (props) => {
    const {
        visible,
        onAfterOpen,
        // onRequestClose,
        userInfo,
        isLoggedIn,
        logout,
        countNotifications,
        dispatch,
        notificationCount,
    } = props;

    useEffect(() => {
        if (visible && isLoggedIn) {
            countNotifications();
        }
    }, [visible, isLoggedIn, countNotifications]);

    const closeDrawer = () => {
        console.log('close clicked');
        dispatch({
            type: 'drawer/HIDE',
        });
    };

    const router = useRouter();

    const toProfile = () => {
        router.push(`/u/${userInfo.id}`);
        closeDrawer();
    };

    const toSetting = () => {
        router.push('/setting');
        closeDrawer();
    };

    const toNotification = () => {
        router.push('/notification');
        closeDrawer();
    };

    const onClickLogout = () => {
        // localStorage.removeItem('token');
        logout().then((res) => {
            if (res) {
                dispatch({
                    type: 'RESET',
                });
                router.push('/login');
                closeDrawer();
            }
        });
    };

    return (
        <Modal
            closeTimeoutMS={200}
            shouldFocusAfterRender
            overlayClassName={styles.Drawer__Overlay}
            isOpen={visible}
            onAfterOpen={onAfterOpen}
            onRequestClose={closeDrawer}
            style={customStyles}
            contentLabel="editor"
        >
            <div className={styles.header}>
                <div className={styles.title}>
                    个人信息
                </div>
                <Button className={styles['close-button']} onClick={closeDrawer}>
                    <MdClose size={18} color="#ff5252" />
                </Button>
            </div>
            <div className={styles['user-info']}>
                <Avatar
                    className={styles.avatar}
                    borderRadius={2}
                    borderRadiusSm={2}
                    width={40}
                    widthSm={40}
                    height={40}
                    heightSm={40}
                    src={userInfo.avatar}
                    alt="avatar"
                    frame={userInfo.avatarFrame}
                    exp={userInfo.exp}
                    isDesktop
                />
                {/* <div className={styles.avatar}>
                    <img
                        alt="avatar"
                        src={userInfo.avatar}
                        className={styles.avatar}
                    />
                </div> */}
                <div className={styles.nickname}>
                    {userInfo.nickname}
                </div>
            </div>
            <div className={styles['exp-info']}>
                <div className={styles['level-container']}>
                    <Level size="sm" exp={userInfo.exp} />
                </div>
                <ExpProgressBar exp={userInfo.exp} />
            </div>
            <div className={styles['nav-container']}>
                <Button className={styles['nav-item']} onClick={toProfile}>
                    <AiOutlineUser size={20} color="#ff5252" />
                    <div className={styles['nav-item-text']}>
                        我的
                    </div>
                </Button>
                <Button className={styles['nav-item']} onClick={toNotification}>
                    <IoNotificationsOutline size={20} color="#ff5252" />
                    <div className={styles['nav-item-text']}>
                        消息
                    </div>
                    {
                        notificationCount > 0 ? (
                            <div className={styles['notification-count']}>
                                { notificationCount }
                            </div>
                        ) : null
                    }
                </Button>
                <Button className={styles['nav-item']} onClick={toSetting}>
                    <IoSettingsOutline size={20} color="#ff5252" />
                    <div className={styles['nav-item-text']}>
                        设置
                    </div>
                </Button>
            </div>
            <div className={styles.footer}>
                <Button className={styles['nav-item']} onClick={onClickLogout}>
                    <FiLogOut size={20} color="#ff5252" />
                    <div className={styles['nav-item-text']}>
                        登出
                    </div>
                </Button>
            </div>
        </Modal>
    );
};

NavDrawer.propTypes = {
    userInfo: PropTypes.shape({
        id: PropTypes.string,
        avatar: PropTypes.string,
        nickname: PropTypes.string,
        exp: PropTypes.number,
        avatarFrame: PropTypes.shape({}),
    }),
    notificationCount: PropTypes.number,
    visible: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    onAfterOpen: PropTypes.func,
    // onRequestClose: PropTypes.func,
    countNotifications: PropTypes.func,
    logout: PropTypes.func,
    dispatch: PropTypes.func,
};

NavDrawer.defaultProps = {
    userInfo: {
        id: '',
        avatar: '',
        nickname: '',
        exp: 0,
        avatarFrame: {},
    },
    notificationCount: 0,
    visible: false,
    isLoggedIn: undefined,
    onAfterOpen: () => {},
    countNotifications: () => {},
    // onRequestClose: () => {},
    logout: () => {},
    dispatch: () => {},
};

const mapStateToProps = (state) => ({
    userInfo: state.user.info,
    notificationCount: state.notification.count,
    visible: state.drawer.visible,
    isLoggedIn: state.user.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(userActions.logout()),
    countNotifications: () => dispatch(notificationActions.countNotifications()),
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(NavDrawer);
