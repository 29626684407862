import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { useHistory } from 'react-router-dom';
import { useRouter } from 'next/router';
import exp2color from '../../utils/exp2color';
import exp2number from '../../utils/exp2number';
// import Image from 'next/image';
import Image from '../image';
import styles from './index.module.css';
import defaultAvatar from '../../../public/default-avatar.png';

const Avatar = ({
    className,
    width,
    height,
    widthSm,
    heightSm,
    borderRadius,
    borderRadiusSm,
    border,
    src,
    alt,
    toProfileEnabled,
    userId,
    isDesktop,
    frame,
    exp,
}) => {
    const [loading, setLoading] = useState(true);

    const router = useRouter();
    const toProfile = () => {
        if (toProfileEnabled && userId) {
            router.push(`/u/${userId}`);
        }
    };

    const w = isDesktop ? width : widthSm;
    const h = isDesktop ? height : heightSm;
    const br = isDesktop ? borderRadius : borderRadiusSm;
    console.log('src', src);
    console.log('[cc]', frame, exp);

    const type = frame && frame.type;
    const image = frame && frame.image;

    const isDefaultFrame = type === 'DEFAULT' && typeof exp === 'number';

    const renderFrame = () => {
        if (!frame) return null;
        if (isDefaultFrame) {
            const [color1, color2] = exp2color(exp);
            const { curColorPercent } = exp2number(exp);
            return (
                <div
                    className={styles['border-frame']}
                    style={{
                        background: `linear-gradient(90deg, ${color2}, ${curColorPercent.toFixed(1)}%, ${color1})`,
                        transform: 'scale(1.1)',
                        borderRadius: br,
                        zIndex: 1,
                        opacity: loading ? 0 : 1,
                    }}
                />
            );
        }

        if (type === 'IMAGE') {
            return (
                <div className={styles['border-frame']}>
                    <Image
                        style={{
                            transform: 'scale(1.217)',
                        }}
                        src={image}
                        width={w}
                        height={h}
                        alt="avatar-frame"
                    />
                </div>
            );
        }

        return null;
    };

    const frameComponent = renderFrame();

    const onLoadingComplete = () => {
        setLoading(false);
    };

    return (
        <div
            className={`${styles.wrapper} ${className} ${border ? styles.border : ''} ${frameComponent ? styles['transparent-border'] : ''}`}
            style={{
                width: w,
                height: h,
                borderRadius: br,
                // overflow: 'hidden',
                ...(toProfileEnabled && { cursor: 'pointer' }),
            }}
            onClick={toProfile}
            onKeyDown={toProfile}
            role="button"
            tabIndex={0}
        >
            <div className={`${styles.avatar} ${isDefaultFrame ? styles['level-border-frame'] : ''}`}>
                <Image
                    className={styles.avatar}
                    width={w}
                    height={h}
                    alt={alt}
                    local={!src}
                    src={src || defaultAvatar}
                    onLoadingComplete={onLoadingComplete}
                />
            </div>
            { frameComponent }
        </div>
    );
};

Avatar.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    widthSm: PropTypes.number,
    heightSm: PropTypes.number,
    borderRadius: PropTypes.number,
    borderRadiusSm: PropTypes.number,
    border: PropTypes.bool,
    src: PropTypes.string,
    alt: PropTypes.string,
    toProfileEnabled: PropTypes.bool,
    userId: PropTypes.string,
    isDesktop: PropTypes.bool,
    frame: PropTypes.shape({
        type: PropTypes.string,
        image: PropTypes.string,
    }),
    exp: PropTypes.number,
};

Avatar.defaultProps = {
    className: '',
    width: 50,
    height: 50,
    widthSm: 40,
    heightSm: 40,
    borderRadius: 0,
    borderRadiusSm: 0,
    border: true,
    src: '',
    alt: '',
    toProfileEnabled: false,
    userId: '',
    isDesktop: true,
    frame: null,
    exp: null,
};

export default Avatar;
