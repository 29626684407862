import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createWrapper } from 'next-redux-wrapper';
import reducers from './ducks';

const makeStore = (
    // context
) => createStore(
    reducers,
    applyMiddleware(thunkMiddleware),
);

// eslint-disable-next-line import/prefer-default-export
export const wrapper = createWrapper(makeStore, { debug: false });
