import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import exp2color from '../../utils/exp2color';

const Level = ({ exp = 0, size }) => {
    const lv = Math.floor((exp / 100) ** (1 / 1.6));
    const [color] = exp2color(exp);
    const levelFontSize = size === 'md' ? 10 : 8;
    const labelFontSize = size === 'md' ? 12 : 10;
    return (
        <span
            style={{
                display: 'inline-block',
                backgroundColor: '#fff',
                border: `1px solid ${color}`,
                color,
                fontSize: labelFontSize,
                fontWeight: 'bold',
                fontFamily: 'Arial, sans-serif',
                padding: '2px 5px',
                borderRadius: 2,
            }}
        >
            <span
                className={styles['level-text']}
                style={{ fontSize: levelFontSize }}
            >
                Lv
            </span>
            <span>{lv}</span>
        </span>
    );
};

Level.propTypes = {
    exp: PropTypes.number,
    size: PropTypes.oneOf(['sm', 'md']),
};

Level.defaultProps = {
    exp: 0,
    size: 'md',
};

export default Level;
