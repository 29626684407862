const exp2number = (exp) => {
    const curLevel = Math.floor((exp / 100) ** (1 / 1.6)); // 0 0
    const curExp = Math.floor(100 * curLevel ** 1.6); // 0 0
    const nextExp = Math.floor(100 * (curLevel + 1) ** 1.6); // 100 100
    const expDiff = nextExp - curExp; // 100
    const percent = Math.floor(((exp - curExp) / expDiff) * 100); // 100

    let curColorPercent = 0;

    if (curLevel < 50) {
        const remainder = curLevel % 10;
        curColorPercent = remainder ? Math.min(100, (remainder / 10) * 100) : 0;
    }

    return {
        curLevel,
        curExp,
        nextExp,
        expDiff,
        percent,
        curColorPercent,
    };
};

export default exp2number;
