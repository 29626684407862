import { combineReducers } from 'redux';
import user from './user';
import notification from './notification';
import article from './article';
import community from './community';
import popup from './popup';
import drawer from './drawer';
import loader from './loader';
import edit from './edit';
import profile from './profile';
import header from './header';
import p from './p';

const appReducer = combineReducers({
    user,
    notification,
    article,
    community,
    popup,
    drawer,
    header,
    loader,
    edit,
    profile,
    p,
});

const rootReducer = (state, action) => {
    if (action.type === 'RESET') {
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

export default rootReducer;
