import React from 'react';
// import { AiOutlineWeibo } from '@react-icons/all-files/ai';
// import Image from 'next/image';
import Image from '../image';
import { useResponsive } from '../../utils/mediaQuery';
import styles from './index.module.css';
// import Logo from './logo.png';
import LogoGrayImage from '../../../public/logo-gray.svg';

const LogoAspectRatio = 346 / 56;
const LogoHeight = 20;
const LogoWidth = LogoAspectRatio * LogoHeight;

const Footer = () => {
    const { isDesktop } = useResponsive();

    return (
        <div className={styles.container}>
            <div className={styles.footer}>
                {
                    isDesktop && (
                        <div className={styles['logo-container']}>
                            <Image local alt="logo" width={LogoWidth} height={LogoHeight} src={LogoGrayImage} />
                        </div>
                    )
                }
                <div className={`${styles.company} ${styles['margin-right']} ${isDesktop ? '' : styles.mobile}`}>
                    ©2022 SixChan
                </div>
                <a className={`${styles.link} ${styles['margin-right']} ${isDesktop ? '' : styles.mobile}`} href="mailto:contact@sixchan.com">联系我们</a>
                <a className={`${styles.link}`} href="mailto:bug@sixchan.com">BUG反馈</a>
                {/* <a className={`${styles.follow} ${styles.link}`} target="_blank" rel="noreferrer" href="https://weibo.com/u/7565954707">
                    关注我们
                    <AiOutlineWeibo className={styles.weibo} size={18} />
                </a> */}
            </div>
        </div>
    );
};

export default Footer;
