import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import Loader from '../loader';

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        zIndex: 999,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'transparent',
        color: '#fff',
        fontSize: 14,
        fontWeight: 600,
        padding: '8px 12px',
        border: 'none',
    },
};

const GlobalLoader = (props) => {
    const {
        visible,
    } = props;

    return (
        <Modal
            isOpen={visible}
            style={customStyles}
            contentLabel="loader"
        >
            <Loader style={{ fontSize: 6, margin: 0 }} />
        </Modal>
    );
};

GlobalLoader.propTypes = {
    visible: PropTypes.bool,
};

GlobalLoader.defaultProps = {
    visible: true,
};

const mapStateToProps = (state) => ({
    visible: state.loader.visible,
});

// const mapDispatchToProps = (dispatch) => ({
//     setVisible: () => dispatch(userActions.getUserInfo()),
// });

export default connect(mapStateToProps, null)(GlobalLoader);
