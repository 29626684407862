import { useState, useLayoutEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

export const DESKTOP_BREAKPONIT = 1224;

export function useResponsive() {
    const [isClient, setIsClient] = useState(false);

    const isDesktop = useMediaQuery({
        minDeviceWidth: DESKTOP_BREAKPONIT,
    });

    useLayoutEffect(() => {
        if (typeof window !== 'undefined') {
            setIsClient(true);
        }
    }, []);
    console.log('[d]', isDesktop);
    return {
        isDesktop: isClient ? isDesktop : false,
    };
}

export const random = '';
