/* eslint-disable no-underscore-dangle */
import * as userServices from '../services/user';

const namespace = 'profile';
const GET_PROFILE_SUCCESS = `${namespace}/GET_PROFILE_SUCCESS`;
const GET_ARTICLES_SUCCESS = `${namespace}/GET_ARTICLES_SUCCESS`;
const GET_POSTS_SUCCESS = `${namespace}/GET_POSTS_SUCCESS`;
const GET_SUBPOSTS_SUCCESS = `${namespace}/GET_SUBPOSTS_SUCCESS`;
const SET_PAGE = `${namespace}/SET_PAGE`;
const SET_ARTICLE_TYPE = `${namespace}/SET_ARTICLE_TYPE`;
const SET_STATE = `${namespace}/SET_STATE`;

const LIMIT = 10;

export const getProfile = ({
    userId,
}) => (dispatch) => userServices.getProfile({
    userId,
}).then((res) => {
    dispatch({
        type: GET_PROFILE_SUCCESS,
        payload: res,
    });
});

export const getArticles = ({
    userId,
}) => (dispatch, getState) => {
    const { currentPage, type } = getState()[namespace].article;
    return userServices[type === 'audited' ? 'getArticles' : 'getUnauditedArticles']({
        userId,
        limit: LIMIT,
        offset: (currentPage - 1) * LIMIT,
    }).then((res) => {
        dispatch({
            type: GET_ARTICLES_SUCCESS,
            payload: res,
        });
    }).catch((err) => {
        dispatch({
            type: 'popup/SHOW',
            payload: {
                message: err.message,
            },
        });
    });
};

export const getPosts = ({
    userId,
}) => (dispatch, getState) => {
    const { currentPage } = getState()[namespace].post;
    return userServices.getPosts({
        userId,
        limit: LIMIT,
        offset: (currentPage - 1) * LIMIT,
    }).then((res) => {
        dispatch({
            type: GET_POSTS_SUCCESS,
            payload: res,
        });
    }).catch((err) => {
        dispatch({
            type: 'popup/SHOW',
            payload: {
                message: err.message,
            },
        });
    });
};

export const getSubposts = ({
    userId,
}) => (dispatch, getState) => {
    const { currentPage } = getState()[namespace].subpost;
    return userServices.getSubposts({
        userId,
        limit: LIMIT,
        offset: (currentPage - 1) * LIMIT,
    }).then((res) => {
        dispatch({
            type: GET_SUBPOSTS_SUCCESS,
            payload: res,
        });
    }).catch((err) => {
        dispatch({
            type: 'popup/SHOW',
            payload: {
                message: err.message,
            },
        });
    });
};

const Profile = (state = {
    profile: {
        id: '',
        avatar: '',
        nickname: '',
        avatarFrame: null,
        exp: 0,
        articleCount: 0,
        postCount: 0,
        subpostCount: 0,
    },
    type: 'post',
    article: {
        type: 'audited',
        list: null,
        currentPage: 1,
        total: 0,
    },
    // avoid render flicker in article.total
    unauditedArticlesTotal: 0,
    post: {
        list: null,
        currentPage: 1,
        total: 0,
    },
    subpost: {
        list: null,
        currentPage: 1,
        total: 0,
    },
}, action) => {
    switch (action.type) {
        case GET_PROFILE_SUCCESS: {
            const {
                payload,
            } = action;

            return {
                ...state,
                ...payload,
            };
        }

        case GET_ARTICLES_SUCCESS: {
            const {
                payload: {
                    articles,
                    total,
                },
            } = action;
            return {
                ...state,
                article: {
                    ...state.article,
                    list: articles,
                    total,
                },
                ...(state.article.type === 'unaudited' && { unauditedArticlesTotal: total }),
            };
        }

        case GET_POSTS_SUCCESS: {
            const {
                payload: {
                    posts,
                    total,
                },
            } = action;
            return {
                ...state,
                post: {
                    ...state.post,
                    list: posts,
                    total,
                },
            };
        }

        case GET_SUBPOSTS_SUCCESS: {
            const {
                payload: {
                    subposts,
                    total,
                },
            } = action;
            console.log('get subpost', subposts);
            return {
                ...state,
                subpost: {
                    ...state.subpost,
                    list: subposts,
                    total,
                },
            };
        }

        case SET_PAGE: {
            const {
                payload: {
                    page,
                },
            } = action;
            const curType = state.type;
            return {
                ...state,
                [curType]: {
                    ...state[curType],
                    currentPage: page,
                },
            };
        }

        case SET_ARTICLE_TYPE: {
            const {
                payload: {
                    type,
                },
            } = action;
            return {
                ...state,
                article: {
                    ...state.article,
                    type,
                    currentPage: 1,
                },
            };
        }

        case SET_STATE: {
            const {
                payload = {},
            } = action;
            return {
                ...state,
                ...payload,
            };
        }

        default:
            return state;
    }
};

export default Profile;
