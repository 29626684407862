// Quality Color
const COLORS = [
    '#9d9d9d',
    '#52c41a',
    '#0070dd',
    '#a335ee',
    '#ff8000',
    // '#e6cc80'
    '#ff5252',
];

const exp2color = (exp) => {
    const lv = Math.floor((exp / 100) ** (1 / 1.6));
    const colorIndex = Math.floor(lv / 10);
    const color1 = colorIndex > COLORS.length - 1 ? COLORS[COLORS.length - 1] : COLORS[colorIndex];
    const color2 = color1 === COLORS[COLORS.length - 1] ? color1 : COLORS[colorIndex + 1];
    return [color1, color2];
};

export default exp2color;
