import React from 'react';
// import { Link } from 'react-router-dom';
import Link from 'next/link';
import PropTypes from 'prop-types';
import styles from './index.module.css';

const PureLink = ({
    className,
    to,
    grayVisited,
    replace,
    title,
    rel,
    children,
}) => {
    const onClick = (e) => {
        e.stopPropagation();
    };
    return (
        <Link
            prefetch={false}
            href={to}
            replace={replace}
        >
            <a
                role="button"
                tabIndex={0}
                onKeyDown={onClick}
                onClick={onClick}
                className={`${styles.link} ${grayVisited ? styles.visited : ''} ${className}`}
                title={title}
                rel={rel}
            >
                { children }
            </a>
        </Link>
    );
};

PureLink.propTypes = {
    className: PropTypes.string,
    to: PropTypes.string,
    grayVisited: PropTypes.bool,
    replace: PropTypes.bool,
    title: PropTypes.string,
    rel: PropTypes.string,
    children: PropTypes.node,
};

PureLink.defaultProps = {
    className: '',
    to: '/',
    grayVisited: true,
    replace: false,
    title: '',
    rel: '',
    children: null,
};

export default PureLink;
