import { get, post } from '../utils/fetch';

export const getCommunityInfo = (params) => get('/api/community/get', params);

export const getTrendingCommunities = () => get('/api/community/get/trending');

export const nameCheck = (params) => get('/api/community/name/check', params);

export const create = (params) => post('/api/community/create', params);

export const getPosts = (params, options) => get('/api/community/get/posts/cursor', params, options);

export const getPagedPosts = (params, options) => get('/api/community/get/posts', params, options);
