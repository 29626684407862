import { HYDRATE } from 'next-redux-wrapper';

const namespace = 'p';
const SET_STATE = `${namespace}/SET_STATE`;
const SET_SUBPOSTS = `${namespace}/SET_SUBPOSTS`;
const UPDATE_SUBPOST = `${namespace}/UPDATE_SUBPOST`;
const UPDATE_POST_DETAIL = `${namespace}/UPDATE_POST_DETAIL`;
const SAVE_POST = `${namespace}/SAVE_POST`;

const P = (state = {
    currentPostId: null,
    hasMore: false,
    cursor: {},
    postDetail: null,
    subposts: null,
    total: 1,
    communityInfo: null,
    editorValue: '',
    modalEditorValue: '',
    quote: {},
}, action) => {
    switch (action.type) {
        case SET_STATE: {
            const {
                payload = {},
            } = action;
            return {
                ...state,
                ...payload,
            };
        }

        case SET_SUBPOSTS: {
            const {
                payload: {
                    init,
                    subposts,
                },
            } = action;
            const newSubposts = init ? subposts : state.subposts.concat(subposts);
            return {
                ...state,
                subposts: newSubposts,
            };
        }

        case UPDATE_SUBPOST: {
            const {
                payload: {
                    subpostId,
                    updates,
                },
            } = action;
            return {
                ...state,
                subposts: state.subposts.map((s) => {
                    if (s.id === subpostId) {
                        return {
                            ...s,
                            ...updates,
                        };
                    }
                    return s;
                }),
            };
        }

        case UPDATE_POST_DETAIL: {
            const {
                payload: {
                    updates,
                },
            } = action;
            return {
                ...state,
                postDetail: {
                    ...state.postDetail,
                    ...updates,
                },
            };
        }

        case SAVE_POST: {
            const {
                payload: {
                    id,
                    content,
                    title,
                    lastEditedAt,
                },
            } = action;
            console.log('[prev]', state, action.payload);
            return {
                ...state,
                postDetail: state.currentPostId === id && state.postDetail ? {
                    ...state.postDetail,
                    content,
                    title,
                    lastEditedAt,
                } : state.postDetail,
            };
        }

        case HYDRATE: {
            return {
                ...state,
                ...action.payload[namespace],
            };
        }

        default:
            return state;
    }
};

export default P;
