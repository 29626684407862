import queryString from 'query-string';
import 'isomorphic-fetch';

// if (typeof window === 'undefined') {
//     globalThis.localStorage = {
//         getItem: () => {},
//         removeItem: () => {},
//     };
// }

const isDev = process.env.NODE_ENV === 'development';

// TODO: prod domain name
const PREFIX = isDev ? 'http://localhost:3000' : 'http://server:9999';

// server side needs absolute urls
const addUrlPrefix = (url) => {
    if (typeof window === 'undefined') {
        if (
            url.indexOf('http://') !== 0
            && url.indexOf('https://') !== 0
            && url.indexOf('/') === 0
        ) {
            // not an absolute url
            return `${PREFIX}${url}`;
        }
    }
    return url;
};

async function fetchBase(apiurl, options) {
    let url = apiurl;
    let opt = options;

    opt = opt || {};
    opt.method = opt.method || 'GET';
    opt.credentials = opt.credentials || 'include';
    opt.withCredentials = true;

    // const token = localStorage.getItem('token');
    const defaultHeaders = {
        Accept: 'application/json',
        // ...(token && { Authorization: `Bearer ${token}` }),
    };
    opt.headers = opt.headers ? Object.assign(opt.headers, defaultHeaders) : defaultHeaders;

    if (opt.query) {
        url = `${url}?${queryString.stringify(opt.query)}`;
    }
    console.log('!opt', opt);
    const res = await fetch(addUrlPrefix(url), opt);
    console.log('!headers', res.headers);
    // TODO: redirect when use is not logged in
    if (res.status === 401) {
        const error = await res.json();
        // console.log('[ee]', error, error.errors.message);
        const errorMsg = error && error.errors && error.errors.msg;
        throw new Error(errorMsg || '登录错误');
        // token expired or not logged in
        // clear expired token
        // localStorage.removeItem('token');
        // throw new Error('未登录');
    } else if (res.status >= 400 && res.status < 600) {
        const error = await res.json();
        let msg = '未知错误';
        if (error && error.errors && Array.isArray(error.errors)) {
            msg = '参数错误';
        } else if (error && error.errors && error.errors.msg) {
            msg = error.errors.msg;
        }
        throw new Error(msg);
    }

    let json = { code: 200 };

    if (opt.raw) {
        return res;
    }

    // 如果是HEAD请求，不需要data
    if (!opt.method.toUpperCase() !== 'HEAD') {
        json = await res.json();
    }

    return json;
}

/**
 * GET 方法，
 * @param {*} url api url
 * @param {*} data 传递的数据，GET 请求 queryString 到url上
 * @param {*} options 预留的其他配置项
 */
export const get = (url, data = {}, options = {}) => fetchBase(url, {
    method: 'GET',
    query: data,
    ...options,
});

/**
 * POST, 数据格式为 application/json
 * @param {*} url
 * @param {*} data
 * @param {*} options
 */
export const post = (url, data = {}, options = {}) => {
    const body = JSON.stringify(data);
    return fetchBase(url, {
        method: 'POST',
        body,
        headers: {
            'Content-Type': 'application/json',
        },
        ...options,
    });
};

/**
 * POST, 数据格式为 multipart/form-data
 * @param {*} url
 * @param {*} data
 * @param {*} options
 */
export const postFormData = (url, data = {}, options = {}) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
    });

    return fetchBase(url, {
        method: 'POST',
        body: formData,
        ...options,
    });
};

export default {
    get,
    post,
    postFormData,
};
