/* eslint-disable no-underscore-dangle */
import { HYDRATE } from 'next-redux-wrapper';
import * as articleServices from '../services/article';
import * as communityServices from '../services/community';

const namespace = 'article';
const SET_STATE = `${namespace}/SET_STATE`;

const GET_ARTICLES_SUCCESS = `${namespace}/GET_ARTICLES_SUCCESS`;
const GET_ARTICLES_ERROR = `${namespace}/GET_ARTICLES_ERROR`;
const GET_POSTS_SUCCESS = `${namespace}/GET_POSTS_SUCCESS`;
const GET_POSTS_ERROR = `${namespace}/GET_POSTS_ERROR`;
const GET_TRENDING_COMMUNITIES_SUCCESS = `${namespace}/GET_TRENDING_COMMUNITIES_SUCCESS`;

export const LIMIT = 10;

export const getTrendingCommunities = () => (dispatch) => communityServices.getTrendingCommunities()
    .then((res) => {
        dispatch({
            type: GET_TRENDING_COMMUNITIES_SUCCESS,
            payload: {
                ...res,
            },
        });
    }).catch((err) => {
        dispatch({
            type: 'popup/SHOW',
            payload: {
                message: `123${err.message}`,
            },
        });
    });

export const getPosts = (params) => (dispatch, getState) => {
    const { cursor, sort, hasMore } = getState()[namespace];
    const { init, ...restParams } = params;
    if (!init && !hasMore) return null;

    return articleServices.getPosts({
        ...restParams,
        ...(init ? {} : cursor),
        sort,
        limit: LIMIT,
    }).then((res) => {
        dispatch({
            type: GET_POSTS_SUCCESS,
            payload: {
                ...res,
                init: params.init,
            },
        });
    }).catch((err) => {
        dispatch({
            type: GET_POSTS_ERROR,
            payload: err,
        });
    });
};

export const getPagedPosts = ({ pageNum }) => (dispatch, getState) => {
    const { sort } = getState()[namespace];
    return articleServices.getPagedPosts({
        sort,
        offset: (pageNum - 1) * LIMIT,
        limit: LIMIT,
    }).then((res) => {
        dispatch({
            type: GET_POSTS_SUCCESS,
            payload: {
                ...res,
                init: true,
                pageNum,
            },
        });
    }).catch((err) => {
        dispatch({
            type: GET_POSTS_ERROR,
            payload: err,
        });
    });
};

export const getArticles = (params) => (dispatch, getState) => {
    const { cursor, sort, hasMore } = getState()[namespace];
    const { init, ...restParams } = params;
    if (!init && !hasMore) return null;

    return articleServices.getArticles({
        ...restParams,
        ...(init ? {} : cursor),
        sort,
        limit: LIMIT,
    }).then((res) => {
        dispatch({
            type: GET_ARTICLES_SUCCESS,
            payload: {
                ...res,
                init: params.init,
            },
        });
    }).catch((err) => {
        dispatch({
            type: GET_ARTICLES_ERROR,
            payload: err,
        });
    });
};

export const getPagedArticles = ({ pageNum }) => (dispatch, getState) => {
    const { sort } = getState()[namespace];
    return articleServices.getPagedArticles({
        sort,
        offset: (pageNum - 1) * LIMIT,
        limit: LIMIT,
    }).then((res) => {
        dispatch({
            type: GET_ARTICLES_SUCCESS,
            payload: {
                ...res,
                init: true,
                pageNum,
            },
        });
    }).catch((err) => {
        dispatch({
            type: GET_ARTICLES_ERROR,
            payload: err,
        });
    });
};

export const initialState = {
    trendingCommunities: [],
    posts: null,
    sort: 'hot',
    // cursor mode
    cursor: {},
    // pagination mode
    pagination: {
        currentPage: 1,
        total: 0,
    },
    error: null,
    isPostInited: false,
    isCommunityInited: false,
};

const Article = (state = initialState, action) => {
    switch (action.type) {
        // case GET_ARTICLES_SUCCESS: {
        //     const {
        //         payload: {
        //             hasMore,
        //             // cursor
        //             cursor,
        //             // paginated
        //             pageNum,
        //             total,
        //             articles,
        //             init,
        //         },
        //     } = action;
        //     const newArticles = init ? articles : state.articles.concat(articles);
        //     return {
        //         ...state,
        //         hasMore,
        //         ...(cursor && { cursor }),
        //         ...(pageNum && total && {
        //             pagination: {
        //                 total: Math.ceil(total / LIMIT),
        //                 currentPage: pageNum,
        //             },
        //         }),
        //         articles: newArticles,
        //     };
        // }
        // case GET_ARTICLES_ERROR: {
        //     const {
        //         payload,
        //     } = action;
        //     return {
        //         ...state,
        //         error: payload,
        //     };
        // }
        case GET_POSTS_SUCCESS: {
            const {
                payload: {
                    hasMore,
                    // cursor
                    cursor,
                    // paginated
                    pageNum,
                    total,
                    posts,
                    init,
                },
            } = action;
            const newPosts = init ? posts : state.posts.concat(posts);
            return {
                ...state,
                hasMore,
                ...(cursor && { cursor }),
                ...(pageNum && total && {
                    pagination: {
                        total: Math.ceil(total / LIMIT),
                        currentPage: pageNum,
                    },
                }),
                posts: newPosts,
                isPostInited: true,
            };
        }
        case GET_POSTS_ERROR: {
            const {
                payload,
            } = action;
            return {
                ...state,
                error: payload,
            };
        }
        case GET_TRENDING_COMMUNITIES_SUCCESS: {
            const {
                payload: {
                    communities,
                },
            } = action;
            return {
                ...state,
                trendingCommunities: communities,
                isCommunityInited: true,
            };
        }
        case SET_STATE: {
            const {
                payload = {},
            } = action;
            return {
                ...state,
                ...payload,
            };
        }
        case HYDRATE: {
            return {
                ...state,
                ...action.payload[namespace],
            };
        }
        default:
            return state;
    }
};

export default Article;
