import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';

const Loader = ({ style }) => (
    <div className={styles.loader} style={style} />
);

Loader.propTypes = {
    style: PropTypes.shape({}),
};

Loader.defaultProps = {
    style: {
        fontSize: 3,
        margin: 0,
    },
};

export default Loader;
