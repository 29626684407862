const CH_UNITS = ['', ' 万', '', ' 亿'];

const EN_UNITS = ['k', '', 'm'];

const numberFormatter = (number, decimals = 1, ch = false) => {
    const UNITS = ch ? CH_UNITS : EN_UNITS;
    if (number < 1000 && !ch) return number;
    if (number < 10000 && ch) return number;
    if ((!ch && number < 1000000)) {
        // k
        return `${+(number / 1000).toFixed(decimals)}${UNITS[0]}`;
    }
    if (ch && number < 100000000) {
        // 万
        return `${+(number / 10000).toFixed(decimals)}${UNITS[1]}`;
    }

    if (!ch) {
        // m
        return `${+(number / 1000000).toFixed(decimals)}${UNITS[2]}`;
    }

    // 亿
    return `${+(number / 100000000).toFixed(decimals)}${UNITS[3]}`;
};

export default numberFormatter;
