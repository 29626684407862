const namespace = 'header';
const SET_THEME = `${namespace}/SET_THEME`;

const Header = (state = {
    theme: 'normal',
}, action) => {
    switch (action.type) {
        case SET_THEME: {
            const {
                payload: {
                    theme = 'normal',
                },
            } = action;
            return {
                ...state,
                theme,
            };
        }
        default:
            return state;
    }
};

export default Header;
