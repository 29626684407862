// import { HYDRATE } from 'next-redux-wrapper';

const namespace = 'drawer';
const SHOW = `${namespace}/SHOW`;
const HIDE = `${namespace}/HIDE`;

const Drawer = (state = {
    visible: false,
}, action) => {
    switch (action.type) {
        case SHOW: {
            return {
                ...state,
                visible: true,
            };
        }

        case HIDE: {
            return {
                ...state,
                visible: false,
            };
        }

        // case HYDRATE: {
        //     return {
        //         ...state,
        //         ...action.payload[namespace]
        //     }
        // }

        default:
            return state;
    }
};

export default Drawer;
