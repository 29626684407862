import { get, post } from '../utils/fetch';

export const getNotifications = (params) => get('/api/notification/get', params);

export const count = (params) => get('/api/notification/count', params);

export const check = (params) => get('/api/notification/check', params);

export const read = (params) => post('/api/notification/read', params);

export const readAll = (params) => post('/api/notification/read/all', params);

export const deleteNotifications = (params) => post('/api/notification/delete', params);
