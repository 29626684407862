/* eslint-disable no-underscore-dangle */
import isEqual from 'lodash.isequal';
import { HYDRATE } from 'next-redux-wrapper';
import * as communityServices from '../services/community';
import * as postServices from '../services/post';

const namespace = 'community';
const GET_COMMUNITY_INFO_SUCCESS = `${namespace}/GET_COMMUNITY_INFO_SUCCESS`;
const GET_POSTS_SUCCESS = `${namespace}/GET_POSTS_SUCCESS`;
const UPVOTE_SUCCESS = `${namespace}/UPVOTE_SUCCESS`;
const DOWNVOTE_SUCCESS = `${namespace}/DOWNVOTE_SUCCESS`;
const SET_STATE = `${namespace}/SET_STATE`;

const LIMIT = 10;

export const getCommunityInfo = (params) => (dispatch) => communityServices
    .getCommunityInfo(params).then((res) => {
        dispatch({
            type: GET_COMMUNITY_INFO_SUCCESS,
            payload: res,
        });
    });

export const getPagedPosts = (params, options) => (dispatch, getState) => {
    const { currentParams } = getState()[namespace];
    const {
        communityName,
        pageNum,
    } = params;

    const newParams = {
        communityName,
        offset: (pageNum - 1) * LIMIT,
        limit: LIMIT,
    };

    if (isEqual(newParams, currentParams)) return null;

    return communityServices.getPagedPosts({
        communityName,
        offset: (pageNum - 1) * LIMIT,
        limit: LIMIT,
    }, options).then((res) => {
        dispatch({
            type: GET_POSTS_SUCCESS,
            payload: {
                ...res,
                init: true,
                pageNum,
                currentParams: newParams,
            },
        });
    }).catch((error) => {
        dispatch({
            type: 'popup/SHOW',
            payload: {
                message: error.message,
            },
        });
    });
};

export const getPosts = (params, options) => (dispatch, getState) => {
    const { cursor, hasMore, currentParams } = getState()[namespace];

    const {
        communityName,
        init,
    } = params;

    const newParams = {
        communityName,
        limit: LIMIT,
        ...(!init && { lastInteractedAt: cursor }),
    };

    if (!init && !hasMore) return null;
    if (isEqual(newParams, currentParams)) return null;

    return communityServices.getPosts({
        communityName,
        limit: LIMIT,
        ...(!init && { lastInteractedAt: cursor }),
    }, options).then((res) => {
        dispatch({
            type: GET_POSTS_SUCCESS,
            payload: {
                ...res,
                init,
                currentParams: newParams,
            },
        });
    }).catch((error) => {
        dispatch({
            type: 'popup/SHOW',
            payload: {
                message: error.message,
            },
        });
    });
};

export const upvote = (params) => (dispatch) => postServices
    .upvote(params).then((res) => {
        dispatch({
            type: UPVOTE_SUCCESS,
            payload: {
                ...res,
                id: params.id,
            },
        });
        return res;
    }).catch((error) => {
        if (error.message === '未登录') {
            // error is handled at higher level
            throw error;
        } else {
            dispatch({
                type: 'popup/SHOW',
                payload: {
                    message: error.message,
                },
            });
        }
    });

export const downvote = (params) => (dispatch) => postServices
    .downvote(params).then((res) => {
        dispatch({
            type: DOWNVOTE_SUCCESS,
            payload: {
                ...res,
                id: params.id,
            },
        });
        return res;
    }).catch((error) => {
        if (error.message === '未登录') {
            // error is handled at higher level
            throw error;
        } else {
            dispatch({
                type: 'popup/SHOW',
                payload: {
                    message: error.message,
                },
            });
        }
    });

const Community = (state = {
    communityInfo: null,
    posts: null,
    hasMore: false,
    // cursor mode
    lastInteractedAt: null,
    // pagination mode
    pagination: {
        currentPage: 1,
        total: 0,
    },
    currentParams: {},
    error: null,
}, action) => {
    const { type } = action;
    switch (type) {
        case GET_COMMUNITY_INFO_SUCCESS: {
            const {
                payload: {
                    community = {},
                },
            } = action;
            return {
                ...state,
                communityInfo: community,
                error: null,
            };
        }
        case GET_POSTS_SUCCESS: {
            const {
                payload: {
                    posts = [],
                    // cursor
                    cursor,
                    // paginated
                    pageNum,
                    total,
                    hasMore = false,
                    init,
                    currentParams,
                },
            } = action;
            const newPosts = (init ? posts : state.posts.concat(posts));
            return {
                ...state,
                posts: newPosts,
                hasMore,
                ...(cursor && { cursor }),
                ...(pageNum && total && {
                    pagination: {
                        total: Math.ceil(total / LIMIT),
                        currentPage: pageNum,
                    },
                }),
                currentParams,
            };
        }
        case SET_STATE: {
            const {
                payload = {},
            } = action;
            return {
                ...state,
                ...payload,
            };
        }
        case UPVOTE_SUCCESS: {
            const {
                payload: {
                    upvotes,
                    voteStatus,
                    id,
                },
            } = action;

            if (!state.posts) return state;

            const newPosts = state.posts.map((p) => {
                if (p._id === id) {
                    return {
                        ...p,
                        upvotes,
                        voteStatus,
                    };
                }
                return p;
            });
            return {
                ...state,
                posts: newPosts,
            };
        }
        case DOWNVOTE_SUCCESS: {
            const {
                payload: {
                    downvotes,
                    voteStatus,
                    id,
                },
            } = action;

            if (!state.posts) return state;

            const newPosts = state.posts.map((p) => {
                if (p._id === id) {
                    return {
                        ...p,
                        downvotes,
                        voteStatus,
                    };
                }
                return p;
            });
            return {
                ...state,
                posts: newPosts,
            };
        }
        case HYDRATE: {
            const {
                payload,
            } = action;
            return {
                ...state,
                ...payload[namespace],
            };
        }
        default:
            return state;
    }
};

export default Community;
