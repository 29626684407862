import React from 'react';
import PropTypes from 'prop-types';
import numberFormatter from '../../utils/numberFormatter';
import styles from './index.module.css';
import exp2number from '../../utils/exp2number';

const ExpProgressBar = ({ exp }) => {
    const {
        // curLevel,
        curExp,
        // nextExp,
        expDiff,
        percent,
    } = exp2number(exp);
    return (
        <div className={styles.container}>
            <div className={styles.bar}>
                <div
                    className={styles.exp}
                    style={{ width: `${percent}%` }}
                />
                <div className={styles['percent-container']}>
                    <div className={styles.percent}>
                        {`${numberFormatter(exp - curExp)} / ${numberFormatter(expDiff)}`}
                    </div>
                </div>
            </div>
        </div>
    );
};

ExpProgressBar.propTypes = {
    exp: PropTypes.number,
};

ExpProgressBar.defaultProps = {
    exp: 0,
};

export default ExpProgressBar;
