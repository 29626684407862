import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';

const Button = (props) => {
    const {
        children,
        className,
        ...restProps
    } = props;
    return (
        <button
            type="button"
            className={`${styles.button} ${className || ''}`}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...restProps}
        >
            { children }
        </button>
    );
};

Button.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

Button.defaultProps = {
    children: null,
    className: null,
};

export default Button;
