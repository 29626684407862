import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { connect } from 'react-redux';
import styles from './index.module.css';
import Button from '../button';
import Avatar from '../avatar';
import Link from '../link';
import LogoBlackImage from '../../../public/logo-black.svg';
import LogoRedImage from '../../../public/logo-red.svg';

// import Logo from './logo.png';
// import Logo from '/logoRed.svg';
// import LogoBanner from './logoBanner.png';
// import Image from 'next/image'
import Image from '../image';

const LogoAspectRatio = 346 / 56;
const LogoHeight = 20;
const LogoWidth = LogoAspectRatio * LogoHeight;

const Header = ({
    userInfo,
    notificationCount,
    isLoggedIn,
    submitModalVisible,
    // submitValid,
    showSubmitButton,
    dispatch,
    theme,
}) => {
    const router = useRouter();
    console.log('[exec] header');
    const onClick = useCallback(() => {
        router.push('/login');
    }, [router]);

    // const publish = useCallback(() => {
    //     dispatch({
    //         type: 'edit/SET_STATE',
    //         payload: {
    //             submitModalVisible: true,
    //         },
    //     });
    // }, [dispatch]);

    const buttonStyle = {
        padding: '5px 15px',
        fontSize: 12,
    };

    const showDrawer = useCallback(() => {
        console.log('show drawer');
        dispatch({
            type: 'drawer/SHOW',
        });
    }, [dispatch]);

    const toHome = useCallback(() => {
        router.push('/');
    }, [router]);

    // const toEditArticle = useCallback(() => {
    //     router.push('/a/edit');
    // }, [router]);

    return (
        <div className={`${styles.container} ${styles[theme]}`}>
            <div className={styles.header}>
                <div className={styles.left}>
                    <div
                        className={styles['logo-container']}
                        tabIndex={0}
                        role="button"
                        onClick={toHome}
                        onKeyDown={toHome}
                    >
                        <Image local width={LogoWidth} height={LogoHeight} src={theme === 'banner' ? LogoBlackImage : LogoRedImage} />
                    </div>
                </div>
                <div className={styles.right}>
                    {/* {
                        isLoggedIn && (
                            <Route exact path="/">
                                <Button
                                    style={buttonStyle}
                                    className={`${styles.button} ${styles[theme]}`}
                                    onClick={toEditArticle}
                                >
                                    <Link
                                        className={`${styles['button-text']} ${styles[theme]}`}
                                        grayVisited={false}
                                        to="/a/edit"
                                    >
                                        投稿
                                    </Link>
                                </Button>
                            </Route>
                        )
                    } */}
                    {
                        (!submitModalVisible && showSubmitButton) && (
                            <>
                                {/* <Route exact path="/a/edit">
                                    <Button
                                        disabled={!submitValid}
                                        style={buttonStyle}
                                        className={styles.button}
                                        onClick={publish}
                                    >
                                        发布
                                    </Button>
                                </Route>
                                <Route exact path="/a/edit/:articleId">
                                    <Button
                                        disabled={!submitValid}
                                        style={buttonStyle}
                                        className={styles.button}
                                        onClick={publish}
                                    >
                                        保存
                                    </Button>
                                </Route> */}
                            </>
                        )
                    }
                    {
                        isLoggedIn ? (
                            <div
                                className={styles['avatar-wrapper']}
                                onClick={showDrawer}
                                onKeyDown={showDrawer}
                                role="button"
                                tabIndex={0}
                            >
                                <Avatar
                                    className={styles.avatar}
                                    borderRadius={2}
                                    borderRadiusSm={2}
                                    width={36}
                                    widthSm={36}
                                    height={36}
                                    heightSm={36}
                                    src={userInfo.avatar}
                                    alt="avatar"
                                    frame={userInfo.avatarFrame}
                                    exp={userInfo.exp}
                                    isDesktop
                                />
                                {
                                    notificationCount > 0 ? (
                                        <div className={styles.dot} />
                                    ) : null
                                }
                            </div>
                        ) : (
                            <Button
                                className={`${styles.button} ${styles[theme]}`}
                                style={{
                                    ...buttonStyle,
                                    marginRight: 0,
                                }}
                                onClick={onClick}
                            >
                                <Link
                                    className={`${styles['button-text']} ${styles[theme]}`}
                                    grayVisited={false}
                                    to="/login"
                                >
                                    登录
                                </Link>
                            </Button>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

Header.propTypes = {
    userInfo: PropTypes.shape({
        avatar: PropTypes.string,
        avatarFrame: PropTypes.shape({}),
        exp: PropTypes.number,
    }),
    notificationCount: PropTypes.number,
    isLoggedIn: PropTypes.bool,
    submitModalVisible: PropTypes.bool,
    // submitValid: PropTypes.bool,
    showSubmitButton: PropTypes.bool,
    dispatch: PropTypes.func,
    theme: PropTypes.string,
};

Header.defaultProps = {
    userInfo: {
        avatar: '',
    },
    notificationCount: 0,
    isLoggedIn: false,
    submitModalVisible: false,
    // submitValid: false,
    showSubmitButton: false,
    dispatch: () => {},
    theme: 'normal',
};

const mapStateToProps = (state) => ({
    userInfo: state.user.info,
    notificationCount: state.notification.count,
    isLoggedIn: state.user.isLoggedIn,
    submitModalVisible: state.edit.submitModalVisible,
    // submitValid: state.edit.submitValid,
    showSubmitButton: state.edit.showSubmitButton,
    theme: state.header.theme,
});

export default connect(mapStateToProps, null)(Header);
