import { get, post } from '../utils/fetch';

export const create = (params) => post('/api/post/create', params);

export const update = (params) => post('/api/post/update', params);

export const getDetail = (params, options) => get('/api/post/get', params, options);

export const poll = (params) => post('/api/post/poll', params);

export const upvote = (params) => post('/api/post/upvote', params);

export const downvote = (params) => post('/api/post/downvote', params);

export const blockPost = (params) => post('/api/post/block', params);

export const log = (params) => post('/api/post/log', params);
