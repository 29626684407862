/* eslint-disable react/prop-types */

// import App from 'next/app'
// import { useEffect } from 'react';
// import { Provider } from 'react-redux';
import React from 'react';
import Model from 'react-modal';
// import { useDispatch, useSelector } from 'react-redux';
// import { useRef } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Script from 'next/script';
// import { countNotifications } from '../ducks/notification';
import { wrapper } from '../store';
import Header from '../components/header';
import Footer from '../components/footer';
import Popup from '../components/popup';
import NavDrawer from '../components/navDrawer';
import GlobalLoader from '../components/globalLoader';
import '../index.css';
import '../tabs.css';

// const NOTIFICATION_INTERVAL = 1000;

function App({ Component, pageProps }) {
    // const store = useStore(initialReduxState)
    // console.log("store", store, initialReduxState);
    // console.log('!!!p [exec]', pageProps);
    // const dispatch = useDispatch();
    // const isLoggedIn = useSelector(state => state.user.isLoggedIn);
    // const intervalId = useRef();
    // useEffect(() => {
    //     if (isLoggedIn && dispatch) {
    //         console.log('create interval');
    //         clearInterval(intervalId.current)
    //         intervalId.current = setInterval(() => {
    //             dispatch(countNotifications());
    //         }, NOTIFICATION_INTERVAL);
    //     }
    //     return () => {
    //         console.log('create interval 2')
    //         clearInterval(intervalId.current);
    //     }
    // }, [dispatch, isLoggedIn]);

    return (
        <>
            <Script
                src="https://www.googletagmanager.com/gtag/js?id=G-JL378TLZ3Z"
                strategy="afterInteractive"
            />
            <Script id="google-analytics" strategy="afterInteractive">
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag() { dataLayer.push(arguments); }
                    gtag('js', new Date());

                    gtag('config', 'G-JL378TLZ3Z');
                `}
            </Script>
            <GoogleReCaptchaProvider
                useRecaptchaNet
                reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
            >
                <div className="App">
                    <Header />
                    <Component {...pageProps} />
                    <Footer />
                    <Popup />
                    <NavDrawer />
                    <GlobalLoader />
                </div>
            </GoogleReCaptchaProvider>
        </>
    );
}

Model.setAppElement('#__next');

export default wrapper.withRedux(App);

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//
//   return { ...appProps }
// }
