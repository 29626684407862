import { post, get } from '../utils/fetch';

export const create = (params) => post('/api/article/create', params);

export const update = (params) => post('/api/article/update', params);

export const getArticles = (params) => get('/api/article/get', params);

export const getPagedArticles = (params) => get('/api/article/get/page', params);

export const getPosts = (params) => get('/api/post/get/cursor', params);

export const getPagedPosts = (params) => get('/api/post/get/page', params);

export const getEditArticleDetail = (params) => get('/api/article/get/detail/edit', params);

export const getArticleDetail = (params) => get('/api/article/get/detail', params);

export const upvote = (params) => post('/api/article/upvote', params);

export const downvote = (params) => post('/api/article/downvote', params);

export const comment = (params) => post('/api/article/comment', params);
