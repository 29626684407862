import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        zIndex: 999,
    },
    content: {
        top: 60,
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, 0%)',
        backgroundColor: '#ff5252',
        color: '#fff',
        fontSize: 14,
        fontWeight: 600,
        padding: '8px 12px',
        border: 'none',
    },
};

const Popup = (props) => {
    const {
        visible,
        message,
        duration,
        onAfterOpen,
        onRequestClose,
        dispatch,
    } = props;

    const timeout = useRef();

    useEffect(() => {
        if (visible) {
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                dispatch({
                    type: 'popup/HIDE',
                });
            }, duration);
        }
    }, [visible, duration, dispatch]);

    return (
        <Modal
            isOpen={visible}
            onAfterOpen={onAfterOpen}
            onRequestClose={onRequestClose}
            style={customStyles}
            contentLabel="alert"
        >
            { message }
        </Modal>
    );
};

Popup.propTypes = {
    visible: PropTypes.bool,
    message: PropTypes.string,
    duration: PropTypes.number,
    onAfterOpen: PropTypes.func,
    onRequestClose: PropTypes.func,
    dispatch: PropTypes.func,
};

Popup.defaultProps = {
    visible: false,
    message: '',
    duration: 1000,
    onAfterOpen: () => {},
    onRequestClose: () => {},
    dispatch: () => {},
};

const mapStateToProps = (state) => ({
    visible: state.popup.visible,
    message: state.popup.message,
    duration: state.popup.duration,
});

// const mapDispatchToProps = (dispatch) => ({
//     setVisible: () => dispatch(userActions.getUserInfo()),
// });

export default connect(mapStateToProps, null)(Popup);
