import { post, get } from '../utils/fetch';

const base = '/api/user';

export const getUserInfo = (options) => get(`${base}/info`, null, options);

export const getUserOwnedAvatarFrames = (params) => get(`${base}/avatarframes`, params);

export const getDefaultAvatarFrames = (params) => get('/api/avatarframe/default', params);

export const usernameCheck = (params) => get(`${base}/username/check`, params);

export const emailCheck = (params) => get(`${base}/email/check`, params);

export const nicknameCheck = (params) => get(`${base}/nickname/check`, params);

export const signup = (params) => post(`${base}/signup`, params);

export const login = (params) => post(`${base}/login`, params);

export const logout = (params) => post(`${base}/logout`, params);

export const forgetPassword = (params) => post(`${base}/forget/password`, params);

export const resetPassword = (params) => post(`${base}/reset/password`, params);

export const setPassword = (params) => post(`${base}/set/password`, params);

export const setAvatar = (params) => post(`${base}/set/avatar`, params);

export const verifyEmail = (params) => post(`${base}/verify/email`, params);

export const validateEmail = (params) => post(`${base}/validate/email`, params);

export const setEmail = (params) => post(`${base}/set/email`, params);

export const subscribePost = (params) => post(`${base}/subscribe/post`, params);

export const subscribeSubpost = (params) => post(`${base}/subscribe/subpost`, params);

export const subscribeArticle = (params) => post(`${base}/subscribe/article`, params);

// profile
export const getProfile = (params) => get(`${base}/profile`, params);

export const getArticles = (params) => get(`${base}/articles`, params);

export const getUnauditedArticles = (params) => get(`${base}/unauditedarticles`, params);

export const getPosts = (params) => get(`${base}/posts`, params);

export const getSubposts = (params) => get(`${base}/subposts`, params);

export const getIp = (params) => get(`${base}/ip`, params);
