/* eslint-disable no-underscore-dangle */
import * as notificationServices from '../services/notification';
import * as userServices from '../services/user';

const namespace = 'notification';
const GET_NOTIFICATIONS_SUCCESS = `${namespace}/GET_NOTIFICATIONS_SUCCESS`;
const READ_NOTIFICATIONS_SUCCESS = `${namespace}/READ_NOTIFICATIONS_SUCCESS`;
const READ_ALL_NOTIFICATIONS_SUCCESS = `${namespace}/READ_ALL_NOTIFICATIONS_SUCCESS`;
const CHECK_NOTIFICATIONS_SUCCESS = `${namespace}/CHECK_NOTIFICATIONS_SUCCESS`;
const COUNT_NOTIFICATIONS_SUCCESS = `${namespace}/COUNT_NOTIFICATIONS_SUCCESS`;
const SELECT_NOTIFICATION = `${namespace}/SELECT_NOTIFICATION`;
const SUBSCRIBE_POST_SUCCESS = `${namespace}/SUBSCRIBE_POST_SUCCESS`;
const SUBSCRIBE_SUBPOST_SUCCESS = `${namespace}/SUBSCRIBE_SUBPOST_SUCCESS`;
const SUBSCRIBE_ARTICLE_SUCCESS = `${namespace}/SUBSCRIBE_ARTICLE_SUCCESS`;
const SET_PAGE = `${namespace}/SET_PAGE`;
const SET_STATE = `${namespace}/SET_STATE`;

const LIMIT = 10;
export const getNotifications = () => (dispatch, getState) => {
    const { type } = getState()[namespace];
    const { currentPage } = getState()[namespace][type];
    return notificationServices.getNotifications({
        type,
        limit: LIMIT,
        offset: (currentPage - 1) * LIMIT,
    }).then((res) => {
        dispatch({
            type: GET_NOTIFICATIONS_SUCCESS,
            payload: {
                ...res,
                type,
            },
        });
    }).catch((err) => {
        dispatch({
            type: 'popup/SHOW',
            payload: {
                message: err.message,
            },
        });
    });
};

export const readNotifications = ({
    ids,
}) => (dispatch, getState) => {
    const { type } = getState()[namespace];
    notificationServices.read({
        ...(ids.length === 1 && { id: ids[0] }),
        ...(ids.length > 1 && { ids }),
        type,
    }).then((res) => {
        dispatch({
            type: READ_NOTIFICATIONS_SUCCESS,
            payload: {
                ...res,
                ids,
                type,
            },
        });
    }).catch((err) => {
        dispatch({
            type: 'popup/SHOW',
            payload: {
                message: err.message,
            },
        });
    });
};

export const readAllNotifications = () => (dispatch, getState) => {
    const { type } = getState()[namespace];
    notificationServices.readAll({
        type,
    }).then((res) => {
        dispatch({
            type: READ_ALL_NOTIFICATIONS_SUCCESS,
            payload: {
                ...res,
                type,
            },
        });
    }).catch((err) => {
        dispatch({
            type: 'popup/SHOW',
            payload: {
                message: err.message,
            },
        });
    });
};

export const deleteNotifications = ({
    ids,
}) => (dispatch) => notificationServices.deleteNotifications({
    ...(ids.length === 1 && { id: ids[0] }),
    ...(ids.length > 1 && { ids }),
}).catch((err) => {
    dispatch({
        type: 'popup/SHOW',
        payload: {
            message: err.message,
        },
    });
});

export const checkNotifications = ({
    type,
} = {}) => (dispatch) => notificationServices.check({
    ...(type && { type }),
}).then((res) => {
    dispatch({
        type: CHECK_NOTIFICATIONS_SUCCESS,
        payload: {
            ...res,
        },
    });
}).catch((err) => {
    dispatch({
        type: 'popup/SHOW',
        payload: {
            message: err.message,
        },
    });
});

export const countNotifications = () => (dispatch) => notificationServices.count().then((res) => {
    dispatch({
        type: COUNT_NOTIFICATIONS_SUCCESS,
        payload: {
            ...res,
        },
    });
}).catch((err) => {
    dispatch({
        type: 'popup/SHOW',
        payload: {
            message: err.message,
        },
    });
});

export const subscribePost = ({
    postId,
}) => (dispatch) => userServices.subscribePost({
    postId,
}).then((res) => {
    const { subscribed = false } = res;
    dispatch({
        type: SUBSCRIBE_POST_SUCCESS,
        payload: {
            postId,
            subscribed,
        },
    });
    dispatch({
        type: 'popup/SHOW',
        payload: {
            message: `${subscribed ? '' : '取消'}订阅成功`,
        },
    });
}).catch((err) => {
    dispatch({
        type: 'popup/SHOW',
        payload: {
            message: err.message,
        },
    });
});

export const subscribeSubpost = ({
    subpostId,
}) => (dispatch) => userServices.subscribeSubpost({
    subpostId,
}).then((res) => {
    const { subscribed = false } = res;
    dispatch({
        type: SUBSCRIBE_SUBPOST_SUCCESS,
        payload: {
            subpostId,
            subscribed,
        },
    });
    dispatch({
        type: 'popup/SHOW',
        payload: {
            message: `${subscribed ? '' : '取消'}订阅成功`,
        },
    });
}).catch((err) => {
    dispatch({
        type: 'popup/SHOW',
        payload: {
            message: err.message,
        },
    });
});

export const subscribeArticle = ({
    articleId,
}) => (dispatch) => userServices.subscribeArticle({
    articleId,
}).then((res) => {
    const { subscribed = false } = res;
    dispatch({
        type: SUBSCRIBE_ARTICLE_SUCCESS,
        payload: {
            articleId,
            subscribed,
        },
    });
    dispatch({
        type: 'popup/SHOW',
        payload: {
            message: `${subscribed ? '' : '取消'}订阅成功`,
        },
    });
}).catch((err) => {
    dispatch({
        type: 'popup/SHOW',
        payload: {
            message: err.message,
        },
    });
});

const Notification = (state = {
    count: 0,
    type: 'post',
    post: {
        list: null,
        currentPage: 1,
        total: 0,
        unread: false,
    },
    mention: {
        list: null,
        currentPage: 1,
        total: 0,
        unread: false,
    },
    // article: {
    //     list: null,
    //     currentPage: 1,
    //     total: 0,
    //     unread: false,
    // },
}, action) => {
    const { type: actionType } = action;
    switch (actionType) {
        case GET_NOTIFICATIONS_SUCCESS: {
            const {
                payload: {
                    notifications = [],
                    total = 0,
                    type,
                },
            } = action;
            return {
                ...state,
                [type]: {
                    ...state[type],
                    list: notifications,
                    total,
                },
            };
        }

        case READ_NOTIFICATIONS_SUCCESS: {
            const {
                payload: {
                    ids,
                    type,
                },
                payload,
            } = action;
            const newCount = state.count > ids.length ? state.count - ids.length : 0;
            return {
                ...state,
                count: newCount,
                [type]: {
                    ...state[type],
                    list: state[type].list ? state[type].list.map((n) => {
                        if (ids.includes(n._id)) {
                            return {
                                ...n,
                                read: true,
                            };
                        }
                        return n;
                    }) : null,
                    unread: !!payload[type],
                },
            };
        }

        case READ_ALL_NOTIFICATIONS_SUCCESS: {
            const {
                payload: {
                    type,
                },
            } = action;
            return {
                ...state,
                [type]: {
                    ...state[type],
                    list: state[type].list ? state[type].list.map((n) => ({
                        ...n,
                        read: true,
                    })) : null,
                    unread: false,
                },
            };
        }

        case CHECK_NOTIFICATIONS_SUCCESS: {
            const {
                payload,
            } = action;
            const getNewUnread = (type) => (typeof payload[type] === 'boolean' ? payload[type] : state[type].unread);
            return {
                ...state,
                mention: {
                    ...state.mention,
                    unread: getNewUnread('mention'),
                },
                post: {
                    ...state.post,
                    unread: getNewUnread('post'),
                },
                // article: {
                //     ...state.article,
                //     unread: getNewUnread('article'),
                // },
            };
        }

        case SUBSCRIBE_ARTICLE_SUCCESS: {
            const {
                payload: {
                    articleId,
                    subscribed,
                },
            } = action;
            const updateArticleSubscribeStatus = (n) => {
                const isComment = n.type.includes('COMMENT');
                if (isComment && n.comment.articleId._id === articleId) {
                    return {
                        ...n,
                        subscribed,
                    };
                }
                return n;
            };
            const types = ['mention', 'article', 'post'];
            const updates = types.reduce((acc, cur) => {
                acc[cur] = {
                    ...state[cur],
                    list: state[cur].list ? state[cur].list.map(updateArticleSubscribeStatus)
                        : state[cur].list,
                };
                return acc;
            }, {});
            return {
                ...state,
                ...updates,
            };
        }

        case SUBSCRIBE_POST_SUCCESS: {
            const {
                payload: {
                    postId,
                    subscribed,
                },
            } = action;
            const updatePostSubscribeStatus = (n) => {
                const isComment = n.type.includes('COMMENT');
                if (!isComment && n.subpost.post._id === postId) {
                    return {
                        ...n,
                        subscribed,
                    };
                }
                return n;
            };
            const types = [
                'mention',
                // 'article',
                'post',
            ];
            const updates = types.reduce((acc, cur) => {
                acc[cur] = {
                    ...state[cur],
                    list: state[cur].list ? state[cur].list.map(updatePostSubscribeStatus)
                        : state[cur].list,
                };
                return acc;
            }, {});
            return {
                ...state,
                ...updates,
            };
        }

        case SUBSCRIBE_SUBPOST_SUCCESS: {
            const {
                payload: {
                    subpostId,
                    subscribed,
                },
            } = action;
            const updateSubpostSubscribeStatus = (n) => {
                const isSubpostReply = n.type === 'SUBPOST_REPLY';
                if (isSubpostReply && n.parentSubpost && n.parentSubpost._id === subpostId) {
                    return {
                        ...n,
                        subscribed,
                    };
                }
                return n;
            };
            return {
                ...state,
                mention: {
                    ...state.mention,
                    list: state.mention.list ? state.mention.list.map(updateSubpostSubscribeStatus)
                        : state.mention.list,
                },
            };
        }

        case COUNT_NOTIFICATIONS_SUCCESS: {
            const {
                payload: {
                    count,
                },
            } = action;
            return {
                ...state,
                count,
            };
        }

        case SELECT_NOTIFICATION: {
            const {
                payload: {
                    ids,
                },
            } = action;
            const curType = state.type;
            return {
                ...state,
                [curType]: {
                    ...state[curType],
                    list: state[curType].list ? state[curType].list.map((n) => {
                        if (typeof ids[n._id] === 'boolean') {
                            return {
                                ...n,
                                selected: ids[n._id],
                            };
                        }
                        return n;
                    }) : null,
                },
            };
        }

        case SET_PAGE: {
            const {
                payload: {
                    page,
                },
            } = action;
            const curType = state.type;
            return {
                ...state,
                [curType]: {
                    ...state[curType],
                    currentPage: page,
                },
            };
        }

        case SET_STATE: {
            const {
                payload = {},
            } = action;
            return {
                ...state,
                ...payload,
            };
        }

        default:
            return state;
    }
};

export default Notification;
