/* eslint-disable no-underscore-dangle */
import * as articleServices from '../services/article';
// import * as postServices from '../services/post';

const namespace = 'edit';
const GET_ARTICLE_DETAIL_SUCCESS = `${namespace}/GET_ARTICLE_DETAIL_SUCCESS`;
const SET_STATE = `${namespace}/SET_STATE`;

export const getArticleDetail = (params) => (dispatch) => articleServices
    .getEditArticleDetail(params).then((res) => {
        dispatch({
            type: GET_ARTICLE_DETAIL_SUCCESS,
            payload: res,
        });
    });

const Edit = (state = {
    uneditedArticle: null,
    title: '',
    subtitle: '',
    content: '',
    headerImage: '',
    tags: [],
    submitModalVisible: false,
    auditedOnce: false,
    submitValid: false,
    showSubmitButton: false,
    publishValid: false,
}, action) => {
    switch (action.type) {
        case GET_ARTICLE_DETAIL_SUCCESS: {
            const {
                payload: {
                    article: {
                        title,
                        subtitle,
                        content,
                        headerImage,
                        tags,
                        auditedOnce,
                    },
                },
            } = action;
            return {
                ...state,
                title,
                subtitle,
                content,
                headerImage,
                tags: tags.map((t) => t._id),
                auditedOnce,
                // used for determining if article has been changed
                uneditedArticle: {
                    title,
                    ...(subtitle && { subtitle }),
                    content,
                    ...(headerImage && { headerImage }),
                    tags: tags.map((t) => t._id),
                },
            };
        }
        case SET_STATE: {
            const {
                payload = {},
            } = action;
            return {
                ...state,
                ...payload,
            };
        }
        default:
            return state;
    }
};

export default Edit;
