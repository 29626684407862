import React from 'react';
import PropTypes from 'prop-types';
import NextImage from 'next/image';
import Thumbor from '../../utils/thumbor';
// import formatImageSrc from '../../utils/formatImageSrc';
/*
const Image = ({
    src,
    width,
    height,
    alt,
    ...props
}) => {
    // const normalizedUri = formatImageSrc({
    //     src,
    //     width,
    //     height,
    // });
    const dimensionProps = width && height ? {
        width,
        height,
    } : {};
    // console.log('what image', normalizedUri, dimensionProps);
    return (
        <img
            src={src}
            alt={alt}
            {...dimensionProps}
            {...props}
        />
    );
};
*/

function getInt(x) {
    if (typeof x === 'number') {
        return x;
    }
    if (typeof x === 'string') {
        return parseInt(x, 10);
    }
    return undefined;
}

const Image = ({
    src,
    width,
    height,
    alt,
    local,
    ...props
}) => {
    const heightInt = getInt(height);
    const widthInt = getInt(width);

    const dimensionProps = widthInt && heightInt ? {
        width: widthInt,
        height: heightInt,
    } : {};

    // const regex = new RegExp(`^https:\/\/img\.sixchan\.com\/.+\/([^\/]+\/[^\/]+)$`);
    // const regex = new RegExp('^https://img.sixchan.com/.+/([^/]+/[^/]+)$');
    const regex = new RegExp('^https://img.sixchan.com/.+/(.+)$');
    const match = typeof src === 'string' ? regex.exec(src) : null;
    const loaderProps = !local && src && match && match[1] ? {
        loader: ({
            // src: imageSrc,
            width: imageWidth,
            // quality
        }) => {
            // const imagePath = src.substring(src.lastIndexOf('/') + 1);
            // console.log('[l]', src);
            // reset thumbor
            Thumbor.filtersCalls = [];
            Thumbor.fitInFlag = false;
            if (match[1] === 'notfound@2x.png') {
                // reserved not found image
                // use fit in and fill(e9eff1) to keep ratio
                const aspectRatio = widthInt / heightInt;
                return Thumbor
                    .setImagePath(match[1])
                    .fitIn(imageWidth, Math.round(imageWidth / aspectRatio))
                    .filter('fill(e9eff1)')
                    .buildUrl();
            }
            // keep image ratio
            return Thumbor.setImagePath(match[1]).resize(imageWidth, 0).buildUrl();
        },
    } : {};

    // for other domain img, use img to save bandwidth
    if (!local && (!match || Object.keys(dimensionProps).length === 0)) {
        const {
            // eslint-disable-next-line react/prop-types
            objectFit,
            // eslint-disable-next-line react/prop-types
            style,
            ...restProps
        } = props;

        let mergedStyle = {};

        if (style) {
            mergedStyle.style = style;
        }

        if (objectFit) {
            mergedStyle = {
                style: {
                    ...(mergedStyle.style || {}),
                    objectFit,
                },
            };
        }

        return <img src={src} alt={alt} {...dimensionProps} {...restProps} {...mergedStyle} />;
    }

    return (
        <NextImage
            src={src}
            alt={alt}
            {...dimensionProps}
            {...loaderProps}
            {...props}
        />
    );
};

Image.propTypes = {
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    width: PropTypes.number,
    height: PropTypes.number,
    alt: PropTypes.string,
    local: PropTypes.bool,
};

Image.defaultProps = {
    src: '',
    width: 0,
    height: 0,
    alt: '',
    local: false,
};

export default Image;
