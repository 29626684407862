import { HYDRATE } from 'next-redux-wrapper';

const namespace = 'popup';
const SHOW = `${namespace}/SHOW`;
const HIDE = `${namespace}/HIDE`;

const Popup = (state = {
    visible: false,
    message: '',
    duration: 1000,
}, action) => {
    switch (action.type) {
        case SHOW: {
            const {
                payload: {
                    message = '',
                    duration = 1000,
                },
            } = action;
            return {
                ...state,
                message,
                duration,
                visible: true,
            };
        }

        case HIDE: {
            return {
                ...state,
                visible: false,
            };
        }

        case HYDRATE: {
            return {
                ...state,
                ...action.payload[namespace],
            };
        }

        default:
            return state;
    }
};

export default Popup;
