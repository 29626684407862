import { HYDRATE } from 'next-redux-wrapper';
import * as userServices from '../services/user';

const namespace = 'user';
const SET_USER_ISLOGGEDIN = `${namespace}/SET_USER_ISLOGGEDIN`;
export const GET_USER_INFO_SUCCESS = `${namespace}/GET_USER_INFO_SUCCESS`;
export const GET_USER_INFO_ERROR = `${namespace}/GET_USER_INFO_ERROR`;
const GET_USER_OWNED_AVATAR_FRAME_SUCCESS = `${namespace}/GET_USER_OWNED_AVATAR_FRAME_SUCCESS`;
const GET_USER_OWNED_AVATAR_FRAME_ERROR = `${namespace}/GET_USER_OWNED_AVATAR_FRAME_ERROR`;
const GET_DEFAULT_AVATAR_FRAME_SUCCESS = `${namespace}/GET_DEFAULT_AVATAR_FRAME_SUCCESS`;
const GET_DEFAULT_AVATAR_FRAME_ERROR = `${namespace}/GET_DEFAULT_AVATAR_FRAME_ERROR`;
const SET_USER_EMAIL = `${namespace}/SET_USER_EMAIL`;
const SET_USER_AVATAR = `${namespace}/SET_USER_AVATAR`;
const USER_LOGOUT_SUCCESS = `${namespace}/USER_LOGOUT_SUCCESS`;
const USER_LOGOUT_ERROR = `${namespace}/USER_LOGOUT_ERROR`;

export const getDefaultAvatarFrames = (params) => (dispatch) => userServices
    .getDefaultAvatarFrames(params)
    .then((res) => {
        dispatch({
            type: GET_DEFAULT_AVATAR_FRAME_SUCCESS,
            payload: res,
        });
        return res;
    }).catch((err) => {
        dispatch({
            type: GET_DEFAULT_AVATAR_FRAME_ERROR,
            payload: err,
        });
        return null;
    });

export const getUserOwnedAvatarFrames = (params) => (dispatch) => userServices
    .getUserOwnedAvatarFrames(params)
    .then((res) => {
        dispatch({
            type: GET_USER_OWNED_AVATAR_FRAME_SUCCESS,
            payload: res,
        });
        return res;
    }).catch((err) => {
        dispatch({
            type: GET_USER_OWNED_AVATAR_FRAME_ERROR,
            payload: err,
        });
        return null;
    });

export const setUserAvatar = (params) => (dispatch) => userServices
    .setAvatar(params).then((res) => {
        dispatch({
            type: SET_USER_AVATAR,
            payload: res,
        });
    });

export const getUserInfo = (options) => (dispatch) => userServices
    .getUserInfo(options).then((res) => {
        dispatch({
            type: GET_USER_INFO_SUCCESS,
            payload: res,
        });
        return res;
    }).catch((err) => {
        dispatch({
            type: GET_USER_INFO_ERROR,
            payload: err,
        });
        return null;
    });

export const logout = () => (dispatch) => userServices.logout().then((res) => {
    dispatch({
        type: USER_LOGOUT_SUCCESS,
    });
    return res;
}).catch((err) => {
    dispatch({
        type: USER_LOGOUT_ERROR,
        payload: err,
    });
    return null;
});

const defaultUserInfo = {
    avatar: '',
    nickname: '',
    email: '',
    emailVerified: false,
    exp: 0,
    avatarFrame: null,
};

const User = (state = {
    info: {
        ...defaultUserInfo,
        roles: [],
        ownedAvatarFrames: [],
        defaultAvatarFrames: [],
    },
    isLoggedIn: undefined,
    error: null,
}, action) => {
    const { type } = action;
    switch (type) {
        case GET_USER_INFO_SUCCESS: {
            const {
                payload: {
                    user = {},
                },
            } = action;
            return {
                ...state,
                info: user,
                isLoggedIn: true,
                error: null,
            };
        }
        case GET_USER_INFO_ERROR: {
            const {
                payload,
            } = action;
            return {
                error: payload,
                info: {
                    ...defaultUserInfo,
                    roles: [],
                },
                isLoggedIn: false,
            };
        }
        case USER_LOGOUT_SUCCESS: {
            return {
                info: {
                    ...defaultUserInfo,
                    roles: [],
                },
                isLoggedIn: false,
            };
        }
        case USER_LOGOUT_ERROR: {
            const {
                payload,
            } = action;
            return {
                ...state,
                error: payload,
            };
        }
        case GET_USER_OWNED_AVATAR_FRAME_SUCCESS: {
            const {
                payload,
            } = action;
            const ownedAvatarFrames = payload.ownedAvatarFrames || [];
            return {
                ...state,
                info: {
                    ...state.info,
                    ownedAvatarFrames,
                },
            };
        }
        case GET_USER_OWNED_AVATAR_FRAME_ERROR: {
            const {
                payload,
            } = action;
            return {
                ...state,
                error: payload,
            };
        }
        case GET_DEFAULT_AVATAR_FRAME_SUCCESS: {
            const {
                payload,
            } = action;
            const defaultAvatarFrames = payload.defaultAvatarFrames || [];
            return {
                ...state,
                info: {
                    ...state.info,
                    defaultAvatarFrames,
                },
            };
        }
        case GET_DEFAULT_AVATAR_FRAME_ERROR: {
            const {
                payload,
            } = action;
            return {
                ...state,
                error: payload,
            };
        }
        case SET_USER_EMAIL: {
            const {
                payload = {},
            } = action;
            return {
                ...state,
                info: {
                    ...state.info,
                    ...payload,
                },
            };
        }
        case SET_USER_AVATAR: {
            const {
                payload: {
                    avatar,
                    avatarFrame,
                },
            } = action;
            console.log('set avatar', avatar, avatarFrame);
            return {
                ...state,
                info: {
                    ...state.info,
                    ...(avatar && { avatar }),
                    ...(avatarFrame && { avatarFrame }),
                },
            };
        }
        case SET_USER_ISLOGGEDIN: {
            const {
                payload,
            } = action;
            return {
                ...state,
                isLoggedIn: payload,
            };
        }
        case HYDRATE: {
            const {
                payload,
            } = action;
            return {
                ...state,
                ...payload[namespace],
            };
        }
        default:
            return state;
    }
};

export default User;
